import type Mapbox from "mapbox-gl";

export const useMapbox = createSharedComposable(() => {
	const mapboxgl = ref<typeof Mapbox>();

	const loadMapbox = async () => {
		if (mapboxgl.value) return;

		mapboxgl.value = await import("mapbox-gl");
	};

	const waitForMapbox = async () => {
		if (mapboxgl.value) return;

		await until(mapboxgl).toBeTruthy();
	};

	return {
		mapboxgl,
		loadMapbox,
		waitForMapbox
	};
});
