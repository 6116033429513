<script setup lang="ts">
import type { Point } from "~/types/storeLocator";

const props = defineProps<{
	location: Point;
	icon?: string;
}>();

const markerRef = ref();
const marker = ref<mapboxgl.Marker>();
const { waitForMapbox, mapboxgl } = useMapbox();

onMounted(async () => {
	const map = inject<Ref<mapboxgl.Map>>("map");

	if (!map?.value) {
		console.warn("Map not found");
		return;
	}

	await waitForMapbox();

	marker.value = new mapboxgl.value!.Marker({
		element: markerRef.value
	})
		.setLngLat([props.location.lng, props.location.lat])
		.addTo(map.value);
});

onUnmounted(() => {
	marker.value?.remove();
	marker.value = undefined;
});
</script>

<template>
	<div class="">
		<div ref="markerRef">
			<slot>
				<img :src="props.icon" class="w-12 h-12 object-contain cursor-pointer" v-bind="$attrs" />
			</slot>
		</div>
	</div>
</template>
