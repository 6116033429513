<script setup lang="ts">
import "mapbox-gl/dist/mapbox-gl.css";
import { type Map } from "mapbox-gl";
import type { Point } from "~/types/storeLocator";
import { Marker } from "#components";

const props = defineProps<{
	center?: Point;
	zoom?: number;
	accessToken?: string;
	lazy?: boolean;
}>();

const { mapboxgl, loadMapbox } = useMapbox();

const mapInstance = ref<Map>();
const isLoading = ref(false);
const isLoaded = ref(false);
const slots = useSlots();

const mapUrl = computed(() => {
	const defaultSlot = slots.default?.();
	const geojson = defaultSlot?.reduce(
		(markers, slotComponent) => {
			if (Object.is(slotComponent.type, Marker)) {
				markers.features.push({
					type: "Feature",
					geometry: {
						type: "Point",
						coordinates: [slotComponent.props?.location.lng, slotComponent.props?.location.lat]
					},
					properties: {
						"marker-url": encodeURIComponent(slotComponent.props?.icon)
					}
				});
			}
			return markers;
		},
		{
			type: "FeatureCollection",
			features: [] as any[]
		}
	);

	return `/mapbox/styles/v1/mapbox/streets-v12/static/geojson(${JSON.stringify(geojson)})/${props.center?.lng},${
		props.center?.lat
	},${props.zoom}/600x600@2x?&access_token=${props.accessToken}`;
});

onMounted(async () => {
	if (props.lazy) {
		return;
	}
	renderMap();
});

onUnmounted(() => {
	mapInstance.value?.remove();
	mapInstance.value = undefined;
});

const renderMap = async () => {
	if (isLoaded.value) return;

	isLoading.value = true;

	await loadMapbox();

	mapInstance.value = new mapboxgl.value!.Map({
		container: "map",
		style: "mapbox://styles/mapbox/streets-v12",
		center: props.center && [props.center.lng, props.center.lat],
		zoom: props.zoom || 9,
		// @ts-ignore
		language: "nl",
		accessToken: props.accessToken
	});

	mapInstance.value?.on("load", () => {
		isLoaded.value = true;
		isLoading.value = false;
	});
};

defineExpose({
	map: mapInstance
});

provide("map", mapInstance);
</script>

<template>
	<div>
		<div id="map" v-bind="$attrs">
			<div
				v-if="lazy && !isLoaded && !isLoading"
				class="w-full h-full overflow-hidden"
				@mousedown="renderMap"
				@touchstart="renderMap"
			>
				<img :src="mapUrl" class="w-full h-full object-cover object-bottom" />
			</div>
			<div
				class="w-full h-full bg-gray-200 flex flex-col gap-8 items-center justify-center text-gray"
				v-if="isLoading"
			>
				<KippieLoader />
				<p class="text-sm">Kaart wordt geladen</p>
			</div>
		</div>
		<div v-if="isLoaded">
			<slot></slot>
		</div>
	</div>
</template>
